/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ContactInfoDto } from './ContactInfoDto';
import {
    ContactInfoDtoFromJSON,
    ContactInfoDtoFromJSONTyped,
    ContactInfoDtoToJSON,
} from './ContactInfoDto';
import type { BranchDto } from './BranchDto';
import {
    BranchDtoFromJSON,
    BranchDtoFromJSONTyped,
    BranchDtoToJSON,
} from './BranchDto';

/**
 * 
 * @export
 * @interface OrganizationDto
 */
export interface OrganizationDto {
    /**
     * Organization ID
     * @type {number}
     * @memberof OrganizationDto
     */
    id: number;
    /**
     * Organization name
     * @type {string}
     * @memberof OrganizationDto
     */
    organizationName: string;
    /**
     * Organization size
     * @type {number}
     * @memberof OrganizationDto
     */
    organizationSize: number;
    /**
     * Organization commercial number
     * @type {string}
     * @memberof OrganizationDto
     */
    commercialNumber: string | null;
    /**
     * Organization tax number
     * @type {string}
     * @memberof OrganizationDto
     */
    taxNumber: string | null;
    /**
     * Organization Business type
     * @type {number}
     * @memberof OrganizationDto
     */
    businessTypes: number;
    /**
     * Organization contact info including address, phone number, etc..
     * @type {ContactInfoDto}
     * @memberof OrganizationDto
     */
    contactInfo?: ContactInfoDto;
    /**
     * Organization contact info ID
     * @type {number}
     * @memberof OrganizationDto
     */
    contactInfoId: number | null;
    /**
     * List of branches belonging to the organization
     * @type {BranchDto}
     * @memberof OrganizationDto
     */
    branches?: BranchDto;
    /**
     * Timestamp organization was created at
     * @type {Date}
     * @memberof OrganizationDto
     */
    createdAt: Date;
    /**
     * Timestamp organization was updated at
     * @type {Date}
     * @memberof OrganizationDto
     */
    updatedAt: Date;
    /**
     * Soft delete flag
     * @type {boolean}
     * @memberof OrganizationDto
     */
    disabled: boolean;
}

/**
 * Check if a given object implements the OrganizationDto interface.
 */
export function instanceOfOrganizationDto(value: object): value is OrganizationDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('organizationName' in value) || value['organizationName'] === undefined) return false;
    if (!('organizationSize' in value) || value['organizationSize'] === undefined) return false;
    if (!('commercialNumber' in value) || value['commercialNumber'] === undefined) return false;
    if (!('taxNumber' in value) || value['taxNumber'] === undefined) return false;
    if (!('businessTypes' in value) || value['businessTypes'] === undefined) return false;
    if (!('contactInfoId' in value) || value['contactInfoId'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('disabled' in value) || value['disabled'] === undefined) return false;
    return true;
}

export function OrganizationDtoFromJSON(json: any): OrganizationDto {
    return OrganizationDtoFromJSONTyped(json, false);
}

export function OrganizationDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrganizationDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'organizationName': json['organizationName'],
        'organizationSize': json['organizationSize'],
        'commercialNumber': json['commercialNumber'],
        'taxNumber': json['taxNumber'],
        'businessTypes': json['businessTypes'],
        'contactInfo': json['contactInfo'] == null ? undefined : ContactInfoDtoFromJSON(json['contactInfo']),
        'contactInfoId': json['contactInfoId'],
        'branches': json['branches'] == null ? undefined : BranchDtoFromJSON(json['branches']),
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (new Date(json['updatedAt'])),
        'disabled': json['disabled'],
    };
}

export function OrganizationDtoToJSON(value?: OrganizationDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'organizationName': value['organizationName'],
        'organizationSize': value['organizationSize'],
        'commercialNumber': value['commercialNumber'],
        'taxNumber': value['taxNumber'],
        'businessTypes': value['businessTypes'],
        'contactInfo': ContactInfoDtoToJSON(value['contactInfo']),
        'contactInfoId': value['contactInfoId'],
        'branches': BranchDtoToJSON(value['branches']),
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': ((value['updatedAt']).toISOString()),
        'disabled': value['disabled'],
    };
}

