/* tslint:disable */
/* eslint-disable */
/**
 * Areeb Admin API
 * Areeb Admin REST API
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserDto } from './UserDto';
import {
    UserDtoFromJSON,
    UserDtoFromJSONTyped,
    UserDtoToJSON,
} from './UserDto';

/**
 * 
 * @export
 * @interface UsersResponseDto
 */
export interface UsersResponseDto {
    /**
     * 
     * @type {Array<UserDto>}
     * @memberof UsersResponseDto
     */
    data: Array<UserDto>;
    /**
     * 
     * @type {number}
     * @memberof UsersResponseDto
     */
    queryCount?: number;
    /**
     * 
     * @type {number}
     * @memberof UsersResponseDto
     */
    totalCount?: number;
    /**
     * 
     * @type {number}
     * @memberof UsersResponseDto
     */
    totalPages?: number;
}

/**
 * Check if a given object implements the UsersResponseDto interface.
 */
export function instanceOfUsersResponseDto(value: object): value is UsersResponseDto {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function UsersResponseDtoFromJSON(json: any): UsersResponseDto {
    return UsersResponseDtoFromJSONTyped(json, false);
}

export function UsersResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): UsersResponseDto {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(UserDtoFromJSON)),
        'queryCount': json['queryCount'] == null ? undefined : json['queryCount'],
        'totalCount': json['totalCount'] == null ? undefined : json['totalCount'],
        'totalPages': json['totalPages'] == null ? undefined : json['totalPages'],
    };
}

export function UsersResponseDtoToJSON(value?: UsersResponseDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': ((value['data'] as Array<any>).map(UserDtoToJSON)),
        'queryCount': value['queryCount'],
        'totalCount': value['totalCount'],
        'totalPages': value['totalPages'],
    };
}

